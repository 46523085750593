<template>
  <div class="VideoChatTestPage">
    <GradientBackLayout
      class="Activity"
      :title="$t('activity_type_wat_videochat')"
      :shadowText="$t('activity_type_wat_videochat')">
      <BoxShadow class="VideoChatTestPage--Box">
        <div class="VideoChatTestPage--Form" v-show="step == 1">
          <label>{{$t('videochat_public_test_user_name')}}</label>
          <CommunityInput type="text" :required="true" :placeholder="$t('videochat_public_test_user_name')" v-model="name"/>
          <CommunityButton :disabled="name.length < 2 " v-on:click="step=2;testUserMedia()">{{$t('action_continue')}}</CommunityButton>
        </div>
        <div class="VideoChatTestPage--Media" v-show="step == 2">
          <div class="VideoChatTestPage--Camera">
            <video id="userVideo" autoplay poster="@/assets/img/defaultuser.png">
            </video>
          </div>
          <div class="VideoChatTestPage--Permission">
            <p><img v-if="videoWorking" src="@/assets/img/active.svg"><img v-else src="@/assets/img/no-active.svg"> {{$t('welcome_video')}}</p>
            <p><img v-if="audioWorking" src="@/assets/img/active.svg"><img v-else src="@/assets/img/no-active.svg"> {{$t('videochat_audio')}}</p>
          </div>
          <div class="VideoChatTestPage--Error" v-show="!audioWorking">
            <img src="@/assets/img/no-active-audio.svg">
            <div class="">
              <h1>{{$t('videochat_audio')}}</h1>
              <p>{{$t('videochat_disabled_micro')}} <a :href="getManualForBrowser()" target="_blank">{{getManualForBrowser()}}{{$t('videochat_disabled_info')}}</a></p>
            </div>
          </div>
          <div class="VideoChatTestPage--Error" v-show="!videoWorking">
            <img src="@/assets/img/no-active-audio.svg">
            <div class="">
              <h1>{{$t('welcome_video')}}</h1>
              <p>{{$t('videochat_disabled_video')}} <a :href="getManualForBrowser()" target="_blank">{{$t('videochat_disabled_info')}}</a></p>
            </div>
          </div>
          <CommunityButton v-on:click="testUserMedia()" v-show="!videoWorking || !audioWorking">{{$t('videochat_public_test_active_permissions')}}</CommunityButton>
        </div>
      </BoxShadow>
    </GradientBackLayout>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import { mapGetters, mapMutations  } from 'vuex';
  import GradientBackLayout from '@/layouts/GradientBackLayout.vue'
  import BoxShadow from '@/components/BoxShadow.vue'
  import CommunityButton from '@/components/CommunityButton.vue'
  import CommunityInput from '@/components/CommunityInput.vue';
  import { COMMUNITY_SET_VIDEO_AUDIO_TEST_PREVIEW } from '@/store/community/chat.module';
  import store from '@/store';
  export default {
    name: 'VideoChatTestPage',

    components: {
      GradientBackLayout,
      CommunityButton,
      CommunityInput,
      BoxShadow
    },

    computed: {
      ...mapGetters([
        'theme',
        'communityInfo',
        'communityCurrentActivity',
        'communityCurrentActivityId',
        'communityChatMessagesObs',
        'isClient',
        'isUser',
        'isObservator',
        'isModerator',
        'userProfile',
        'onlineUsers',
      ]),
    },

    data() {
      return {
        audioStream:null,
        videoStream:null,
        videoWorking:false,
        audioWorking:false,
        name:'',
        step:1,
      }
    },

    methods: {
      async testUserMedia(){
        if(navigator.userAgent.indexOf("MiuiBrowser") > -1){
          Swal.fire({
            title:this.$t('msg_videochat_navigator_not_working_title'),
            text:this.$t('msg_videochat_navigator_not_working_text'),
            icon:"info",
            customClass:{popup:"swal-customInfo"}
          })
          return
        }
        if(navigator.mediaDevices == undefined) return
        let vm = this
        await navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function(stream) {
          vm.audioWorking = true
          vm.audioStream = stream
        })
        .catch(function(err) {
          vm.audioWorking = false
          Swal.fire({
            text:vm.$t('videochat_public_test_denied_micro'),
            icon:"info",
            customClass:{popup:"swal-customInfo"}
          })
        });
        await navigator.mediaDevices.getUserMedia({ video: true })
        .then(function(stream) {
          vm.videoWorking = true
          document.querySelector('#userVideo').srcObject = stream
          vm.videoStream = stream
        })
        .catch(function(err) {
          vm.videoWorking = false
          Swal.fire({
            text:vm.$t('videochat_public_test_denied_camera'),
            icon:"info",
            customClass:{popup:"swal-customInfo"}
          })
        });

        store.dispatch(COMMUNITY_SET_VIDEO_AUDIO_TEST_PREVIEW,{name:this.name,videoWorking:this.videoWorking,audioWorking:this.audioWorking})
      },
      getManualForBrowser(){
        let isFirefox = typeof InstallTrigger !== 'undefined';
        let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isEdge = !isIE && !!window.StyleMedia;
        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        if (isFirefox) return "https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro"
        if (isSafari) return "https://support.apple.com/es-es/guide/safari/ibrw7f78f7fe/mac"
        if (isEdge) return "https://support.microsoft.com/es-es/help/4468232/windows-10-camera-microphone-and-privacy"
        if (isChrome) return "https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=es-419"
      },
    },
  };
</script>
<style lang="scss">
.VideoChatTestPage{
  .GradientBack{
    height: 100vh;
    margin-bottom: 30px;
  }
}
</style>
<style scoped lang="scss">
.VideoChatTestPage{
  &--Box{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
  }
  &--Media{
    max-width: 500px;
    border: 1px solid lightgray;
    padding: 25px;
    display: flex;
    flex-direction: column;
    button{
      margin-top: 20px;
    }
  }
  &--Form{
    border: 1px solid lightgray;
    padding: 25px;
    display: flex;
    flex-direction: column;
    label{
      font-size: 19px;
    }
    button{
      margin-top: 40px;
    }
  }
  &--Camera{
    box-sizing: border-box;
    max-height: 268px;
    margin: 10px 0;
    video source, video{
      border: 1px solid #E1E1E1;
      object-fit: cover;
      max-height: 268px;
    }
    video[poster]{
      width:100%;
    }
  }
  &--Permission{
      font-size: 19px;
      color: #3E3E3E;
      display: flex;
      text-transform: capitalize;
      p{
        margin-left: 20px;
        display: flex;
        align-items: center;
        img{
          margin-right:5px;
        }
      }
  }
  &--Error{
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin:10px 0;
    img{
      margin-right: 10px;
      margin-top: 4px;
    }
    h1{
      font-weight: bold;
      text-transform: capitalize;
      font-size: 22px;
    }
    p{
      font-size: 15px;
      color: #747474;
    }
  }
}
</style>
